<template>
    <section class="section-solicitud">
        <div class="container-solicitud">
            <img src="@/assets/logo-quac-morado.svg" class="mb-4">
            <form @submit.prevent="generarSolicitudCredito" class="form-solicitud">
                <p>Solicitud de cupo</p>
                <h1 class="title-section">
                    Accede al crédito <span>QUAC:</span> Rápido, fácil y para todos
                </h1>
                <label for="select-comercio" class="label-form">Selecciona el comercio</label>
                <div class="input-group-quac mb-4">
                    <div class="group-prefix" v-if="currentEcommerce && currentEcommerce.icon != ''">
                        <img :src="currentEcommerce.icon">
                    </div>
                    <select name="select-comercio" id="select-comercio" v-model="currentEcommerce">
                        <option :value="null">
                            Selecciona el comercio
                        </option>
                        <option :key="index" v-for="(comercio, index) in comerciosSoli" :value="comercio">
                            {{ comercio.description }}
                        </option>
                    </select>
                </div>
                <label for="select-comercio" class="label-form">Ingresa el número de documento</label>
                <div class="input-group-quac mb-5">
                    <div class="group-prefix" style="background-color: #ECECEC;">
                        <span>CC</span>
                    </div>
                    <input type="tel" v-model="document">
                </div>
                <p class="mb-4 width-form">
                    Al continuar, autorizo el tratamiento de mis datos personales de acuerdo con la política de privacidad de Quac y acepto los términos y condiciones del crédito. Entiendo que consultar mi historial de crédito es un paso indispensable durante el proceso de aprobación.
                </p>
                <button
                    class="buton buton-primary width-form w-100"
                    :class="!loading && validateForm ? '' : 'btn-disabled'"
                    :disabled="!loading && !validateForm"
                >
                    {{ loading ? 'CARGANDO...' : 'CONTINUAR'}}
                </button>
            </form>
        </div>
        <div class="container-decoration-image">
            <img src="@/assets/img-solicitud.png" class="img-solicitud" v-if="!currentEcommerce">
            <img :src="currentEcommerce.image" class="img-solicitud" v-else>
            <img src="@/assets/float-solicitud-1.svg" class="img-overflow-solicitud">
            <img src="@/assets/float-solicitud-2.svg" class="img-float-solicitud img-float-solicitud_1">
            <img src="@/assets/float-solicitud-3.svg" class="img-float-solicitud img-float-solicitud_2">
            <img src="@/assets/float-solicitud-4.svg" class="img-float-solicitud img-float-solicitud_3">
            <img src="@/assets/float-solicitud-5.svg" class="img-float-solicitud img-float-solicitud_4">
            </div>
    </section>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    data() {
        return {
            currentEcommerce: null,
            document: '',
            loading: false,
        }
    },
    computed: {
        ...mapState('globalState', ['comerciosSoli']),
        validateForm() {
            return this.document.length > 5 && this.currentEcommerce
        },
    },
    watch: {
        document() {
            if (this.document.length > 10) {
                this.document = this.document.slice(0, 10)
            }
        }
    },
    mounted() {
        this.getComerciosSoli()
        this.setLayoutNav('')
        this.setLayoutFooter("footer-basic");
        setTimeout(() => {
            document.querySelector('.section-solicitud').style.minHeight = `calc(100dvh - ${document.querySelector('.section-simple-footer').offsetHeight}px`
        }, 100);
        
    },
    unmounted() {
        this.setLayoutNav('nav-bar')
        this.setLayoutFooter("new-footer");
    },
    methods: {
        ...mapActions('globalState',['setLayoutFooter', 'setLayoutNav', 'getComerciosSoli']),
        generarSolicitudCredito(){
            this.loading = true
            let data = new FormData();
            data.append('documento',this.document)
            data.append('business_id',this.currentEcommerce.value)
            this.axios({
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded'},
                url: '/cupopersonal/preaprobado/solicitud',
                data: data
            })
            .then(response => {
                window.location.href = response.data
                this.loading = false
            })
            .catch(() => {
                this.loading = false
            })
        }
    },
}
</script>
<style lang="scss" scoped>
.section-solicitud {
    display: grid;
    grid-template-columns: 7fr 5fr;
    .container-solicitud {
        background-image: url('../assets/bg-solicitud-2.svg');
        background-size: cover;
        background-position: bottom right;
        background-repeat: no-repeat;
        padding: 1rem;
        width: 100%;
        .form-solicitud {
            max-width: 444px;
            margin-right: auto;
            margin-left: auto;
            padding: 1rem;
            width: 100%;
            h1 {
                color: var(--primaryColorDark);
                font-weight: bold;
                span {
                    color: var(--secundaryColor);
                }
            }
            .btn-disabled {
                background-color: #E3E3E3;
                color: #979796;
                border: none;
            }
        }
    }
    .container-decoration-image {
        background-image: url('../assets/bg-solicitud-1.png');
        background-size: cover;
        background-position: top right;
        background-repeat: no-repeat;
        display: flex;
        position: relative;
        img {
            max-width: 444px;
            margin-top: auto;
        }
        .img-overflow-solicitud {
            position: absolute;
            left: -90px;
            max-width: 270px;
            bottom: 0;
        }
        .img-float-solicitud {
            position: absolute;
            &_1 {
                top: 20%;
                left: -15px;
                max-width: 30px;
                animation: animationUpDown 2.5s ease-in-out infinite alternate;
            }
            &_2 {
                top: 35%;
                left: -67px;
                max-width: 185px;
                animation: animationUpDown 3.1s ease-in-out infinite alternate;
            }
            &_3 {
                top: 17%;
                right: 25%;
                max-width: 90px;
                animation: animationUpDown 2.6s ease-in-out infinite alternate;
            }
            &_4 {
                top: 48%;
                right: 15%;
                max-width: 90px;
                animation: animationUpDown 2.8s ease-in-out infinite alternate;
            }
        }
    }
    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        .container-decoration-image {
            .img-solicitud {
                margin-right: auto;
                margin-left: auto;
                z-index: 1;
                width: 100%;
            }
            .img-overflow-solicitud {
                z-index: 0;
            }
            .img-float-solicitud {
                &_1 {
                    top: 60%;
                    left: 8%;
                    max-width: 18px;
                    z-index: 2;
                }
                &_2 {
                    top: 16%;
                    left: 0;
                    max-width: 119px;
                    z-index: 2;
                }
                &_3 {
                    top: 20%;
                    right: 8%;
                    max-width: 39px;
                    z-index: 2;
                }
                &_4 {
                    top: 48%;
                    right: 8%;
                    max-width: 56px;
                    z-index: 2;
                }
            }
        }
    }
}
</style>